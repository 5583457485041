/* Global Styles */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(to bottom, #0D0D0D, #1F1F1F);
  color: #FFFFFF;
}

button,
input,
select,
textarea,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
}

/* Button Styles */
.MuiButton-containedPrimary {
  background-color: #0FA4AF;
}

.MuiButton-containedPrimary:hover {
  background-color: #0D919E;
}

/* Slick Slider Overflow Adjustment */
.slick-slider,
.slick-list,
.slick-track,
.slick-slide {
  overflow: visible !important;
}

/* Slick Dots Styling */
.slick-dots {
  bottom: 0px !important;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #F76C5E; /* Inactive dot color */
  opacity: 0.5;
}

.slick-dots li.slick-active button:before {
  color: #B34C5F; /* Active dot color */
  opacity: 1;
}

/* Mobile Adjustments */
@media (max-width: 600px) {
  .slick-dots {
    bottom: -10px !important; /* Move dots closer to the carousel */
  }

  .testimonial-section {
    margin-bottom: 30px; /* Prevent overlap with divider */
  }
}
